import { Box, Button, ButtonBase, CircularProgress, FormControlLabel, setRef, Switch, TextField, Typography } from "@mui/material";
import { textAlign } from "@mui/system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react"
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { toJS } from "mobx";
import ClearIcon from '@mui/icons-material/Clear';
import { t } from "i18next";
import VmButton from "./shared-button";
import useStores from "../hooks/use-stores";
import { ITEM_PERFECT_INLINED } from "../constants/style";

const VmActionList = observer(({
  list, label, leftLabel, rightLabel,
  onClose,
  buttonLabel,
  buttonLabelWithoutConfirm,
  onClickConfirmedButton,
  addedRecord = [],
  children = null
}) => {
  const { academicStore } = useStores();
  const [sourceList, setSourceList] = useState<any>(list);
  const [addedList, setAddedList] = useState<any>([]);
  const [confirmButton, setConfirmButton] = useState<boolean>(false);
  const [refreshList, setRefreshList] = useState<any>([]);
  const [searchSubjectKey, setSearchSubjectKey] = useState<string>("");

  useEffect(() => {
    const compare = (a, b) => {
      if ((a.subjectCode < b.subjectCode)) return -1;
      if (a.subjectCode > b.subjectCode) return 1;
      return 0;
    }
    setSourceList(list.sort(compare));
    setRefreshList([]);
    setConfirmButton(false);
  }, [onClose, academicStore.unitList]);

  useEffect(() => {
    if (academicStore.unitQualificationRelationList.data.length > 0 && addedRecord.length > 0
      && sourceList.length > 0) {
      addedRecord.map((item: any) => {
        onAddListItem(item.id, false, item.compulsory);
        setRefreshList([]);
      })
    }
  }, [academicStore.unitQualificationRelationList.data, addedRecord, sourceList])

  const onAddListItem = (id: number, pushToTop?: boolean, compulsory?: boolean) => {
    let item = sourceList.find(s => s.id == id);
    let tempList = addedList;
    item.compulsory = compulsory != undefined && compulsory != null ? compulsory : true;
    if (pushToTop) tempList.unshift(toJS(item));
    else tempList.push(toJS(item));
    setAddedList(tempList);
    setRefreshList([]);
  }

  const onRemoveItem = (index: number) => {
    let tempList = addedList;
    addedList.splice(index, 1);
    tempList = addedList;
    setAddedList(tempList);
    setRefreshList([]);
  }

  return (
    <div className="grid grid-cols-2 gap-4">
      {academicStore.unitList.data.length == 0 ? <Typography className="col-span-2">No unit found, please add some units under the Academic / Unit module</Typography>
        : <>
          <Box>
            <Typography variant="subtitle2">{leftLabel}</Typography>
            <TextField
              value={searchSubjectKey}
              // label={t('SEARCH_SUBJECT')}
              label="Search Unit"
              variant="outlined"
              sx={{ width: '100%', background: 'white', marginTop: 1 }}
              onChange={(value) => setSearchSubjectKey(value.target.value)}
              size="small"
            />
            {/* <Typography variant="subtitle2" marginY={0.5}>{t('SUBJECT_COUNT')}: {sourceList.filter(sub => searchSubjectKey !== "" ?
          sub.subjectCode.toLowerCase().includes(searchSubjectKey.toLowerCase()) : sub.subjectCode !== "").length}</Typography> */}
            <Typography variant="subtitle2" marginY={0.5}>Unit Count: {sourceList.filter(sub => searchSubjectKey !== "" ?
              sub.code.toLowerCase().includes(searchSubjectKey.toLowerCase()) : sub.code !== "").length}</Typography>
            <div className="border p-1" style={{ maxHeight: 250, overflow: 'auto' }}>
              {
                academicStore.loading ? <Box sx={{ textAlign: 'center' }}><CircularProgress /></Box>
                  : sourceList.length > 0 ?
                    sourceList.filter(sub => searchSubjectKey !== "" ?
                      sub.code.toLowerCase().includes(searchSubjectKey.toLowerCase()) : sub.code !== "").
                      map((item: any, index: number) => (
                        <Box key={`action_list_left_${index}`} sx={{ marginBottom: 0.5 }}>
                          <button
                            type="button"
                            className={`${addedList.find(a => a.id == item.id) && "bg-highlight text-white hover:cursor-not-allowed"} 
                    hover:bg-highlight hover:text-white w-full text-left px-2 rounded-md`}
                            onClick={() => onAddListItem(item.id, true)}
                            disabled={addedList.find(a => a.id == item.id)}
                          >
                            {item[label]}
                          </button>
                        </Box>
                      ))
                    : <div className="text-center w-full text-sm">{t('NO_RESULT_FOUND')}</div>
              }
            </div>
          </Box>
          <Box>
            <Typography variant="subtitle2">{rightLabel}</Typography>
            <p className="text-red-500 text-sm mt-2">Note: Switch on indicates it is a COMPULSORY course</p>
            <Button
              variant="outlined"
              size="small"
              style={{ height: 'fit-content', width: 'fit-content' }}
              onClick={() => {
                addedList.map((item: any) => item.compulsory = true);
                setRefreshList([]);
              }}
            >
              Switch All Selected Units to Compulsory
            </Button>
            {/* <Box sx={ITEM_PERFECT_INLINED} className="justify-end">
              <Switch checked={true} size="small" disabled />
            </Box> */}
            <div className="border p-1 mt-2" style={{ maxHeight: 250, overflow: 'auto' }}>
              {
                addedList.length > 0 ?
                  addedList.map((item: any, index: number) => (
                    <Box key={`action_list_right_${index}`} sx={{ marginBottom: 0.5, paddingBottom: 0.5, borderBottomWidth: index == addedList.length - 1 ? 0 : 1 }}>
                      <div className="flex justify-between w-full text-left px-2 rounded-md">
                        <div style={{ marginTop: 3 }}>
                          <Typography>{item[label]}</Typography>
                        </div>
                        <div className="flex">
                          <div style={{ marginTop: 2 }}>
                            {/* Benchmark customised */}
                            <Switch
                              checked={item.compulsory}
                              size="small"
                              onChange={() => {
                                let tempList = addedList;
                                tempList.find(t => t.id == item.id).compulsory = !tempList.find(t => t.id == item.id).compulsory;
                                setAddedList(tempList);
                                setRefreshList([]);
                              }}
                            />
                          </div>
                          <button
                            type="button"
                            className="text-red-500 hover:text-highlight"
                            onClick={() => onRemoveItem(index)}
                          >
                            <ClearIcon fontSize={"small"} color="inherit" />
                          </button>
                        </div>
                      </div>
                    </Box>
                  ))
                  : <div className="text-center w-full text-sm">{t('NO_RESULT_FOUND')}</div>
              }
            </div>
            {
              children &&
              <div className="mt-4">
                {children}
              </div>
            }
            <div className="text-right mt-4">
              {confirmButton ?
                <>
                  <Button
                    variant="outlined"
                    disabled={academicStore.loading}
                    onClick={() => onClickConfirmedButton(addedList)}
                    color="success"
                  >
                    {academicStore.loading ? "Loading..." : buttonLabel}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => setConfirmButton(false)}
                    sx={{ marginLeft: 2 }}
                    color="error"
                  >
                    Cancel
                  </Button>
                </>
                :
                <Button
                  variant="outlined"
                  disabled={academicStore.loading}
                  onClick={() => setConfirmButton(true)}
                >
                  {academicStore.loading ? "Loading..." : buttonLabelWithoutConfirm}
                </Button>}
            </div>
          </Box >
        </>
      }
    </div>
  )
})

export default VmActionList;
